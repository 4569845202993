<template>
    <div class="includepage">
        <section class="blockElement space bg-white timeLine">
                <div class="container">
                    <div class="row">
                        <!-- <div class="col-12 col-lg-3 mb-4">
                            <h2 class="mb-4">Our <span class="secondary">Story</span></h2>
                            <p class="f-18 bold line-35">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                        </div> -->
                        <div class="col-12 col-lg-12">
                            <div class="main-timeline">
                                <ul class="mb-0">
                                    <span class="default-line"></span>
                                    <span class="draw-line"></span>
                                    <li class="timeline">
                                        <article>
                                            <div class="icon"><span class="lineAdded"></span></div>
                                            <div class="timeline-content right d-flex align-items-center">
                                                <div class="stroyImg darkCard">
                                                    <v-lazy-image class="d-block lineBorder" src="/assets/images/baskets.svg" :alt="$t('about.text440')" />
                                                </div>
                                                <div class="content darkCard">
                                                    <h5 class="title">{{$t('about.text43')}}</h5>
                                                    <ul class="description dotList">
                                                        <li>{{$t('about.text44')}}</li>
                                                        <li>{{$t('about.text45')}}</li>
                                                        <li>{{$t('about.text46')}}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </article>
                                    </li>
                                    <li class="timeline">
                                        <article>
                                            <div class="icon"><span class="lineAdded"></span></div>
                                            <div class="timeline-content right d-flex align-items-center">
                                                <div class="stroyImg darkCard">
                                                    <v-lazy-image class="d-block lineBorder" src="/assets/images/stocks.svg" :alt="$t('about.text440')" />
                                                </div>
                                                <div class="content darkCard">
                                                    <h5 class="title">{{$t('about.text47')}}</h5>
                                                    <ul class="description dotList">
                                                        <li>{{$t('about.text48')}}</li>
                                                        <li>{{$t('about.text49')}}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </article>
                                    </li>
                                    <li class="timeline">
                                        <article>
                                            <div class="icon"><span class="lineAdded"></span></div>
                                            <div class="timeline-content right d-flex align-items-center">
                                                <div class="stroyImg darkCard">
                                                    <v-lazy-image class="d-block lineBorder" src="/assets/images/finLogo.svg" :alt="$t('about.text440')" />
                                                </div>
                                                <div class="content darkCard">
                                                    <h5 class="title">{{$t('about.text50')}}</h5>
                                                    <p class="description">
                                                        {{$t('about.text51')}}
                                                    </p>
                                                </div>
                                            </div>
                                        </article>
                                    </li>
                                    <li class="timeline">
                                        <article>
                                            <div class="icon"><span class="lineAdded"></span></div>
                                            <div class="timeline-content left d-flex align-items-center">
                                                <div class="stroyImg darkCard">
                                                    <v-lazy-image class="d-block lineBorder" src="/assets/images/combos.svg" :alt="$t('about.text441')" />
                                                </div>
                                                <div class="content darkCard">
                                                    <h5 class="title">{{$t('about.text52')}}</h5>
                                                    <p class="description">{{$t('about.text53')}}</p>
                                                </div>
                                            </div>
                                        </article>
                                    </li>
                                    <li class="timeline">
                                        <article>
                                        <div class="icon"><span class="lineAdded"></span></div>
                                            <div class="timeline-content d-flex align-items-center">
                                                <div class="stroyImg darkCard">
                                                    <v-lazy-image class="d-block lineBorder" src="/assets/images/Cryptos-copy-trading.svg" :alt="$t('about.text442')" />
                                                </div>
                                                <div class="content darkCard">
                                                    <h5 class="title">{{$t('about.text54')}}</h5>
                                                    <p class="description"><span class="secondary">{{$t('about.text55')}}</span> {{$t('about.text56')}}</p>
                                                </div>
                                            </div>
                                        </article>
                                    </li>
                                    <li class="timeline">
                                        <article>
                                            <div class="icon"><span class="lineAdded"></span></div>
                                            <div class="timeline-content left d-flex align-items-center">
                                                <div class="stroyImg darkCard">
                                                    <v-lazy-image class="d-block lineBorder" src="/assets/images/eu-license.svg" :alt="$t('about.text443')" />
                                                </div>
                                                <div class="content darkCard">
                                                    <h5 class="title">{{$t('about.text57')}}</h5>
                                                    <p class="description">{{$t('about.text58')}} <span class="secondary">{{$t('about.text55')}}</span>{{$t('about.text59')}}</p>
                                                </div>
                                            </div>
                                        </article>
                                    </li>
                                    <li class="timeline">
                                        <article>
                                            <div class="icon"><span class="lineAdded"></span></div>
                                            <div class="timeline-content d-flex align-items-center">
                                                <div class="stroyImg darkCard">
                                                    <v-lazy-image class="d-block lineBorder" src="/assets/images/asia-expantion.svg" :alt="$t('about.text444')" />
                                                </div>
                                                <div class="content darkCard">
                                                    <h5 class="title">{{$t('about.text60')}}</h5>
                                                    <p class="description"> <span class="secondary">{{$t('about.text55')}}</span> {{$t('about.text61')}}<span class="secondary">{{$t('about.text55')}}</span>{{$t('about.text62')}}</p>
                                                </div>
                                            </div>
                                        </article>
                                    </li>
                                    <li class="timeline">
                                        <article>
                                            <div class="icon"><span class="lineAdded"></span></div>
                                            <div class="timeline-content left d-flex align-items-center">
                                                <div class="stroyImg darkCard">
                                                    <v-lazy-image class="d-block lineBorder" src="/assets/images/ZuluguardNew.svg" :alt="$t('about.text445')" />
                                                </div>
                                                <div class="content darkCard">
                                                    <h5 class="title">{{$t('about.text63')}}</h5>
                                                    <p class="description">
                                                        <span class="secondary">{{$t('about.text64')}}</span> {{$t('about.text65')}}
                                                    </p>
                                                </div>
                                            </div>
                                        </article>
                                    </li>
                                    <li class="timeline">
                                        <article>
                                            <div class="icon"><span class="lineAdded"></span></div>
                                            <div class="timeline-content d-flex align-items-center">
                                                <div class="stroyImg darkCard">
                                                    <v-lazy-image class="d-block lineBorder" src="/assets/images/featuresAbout.svg" :alt="$t('about.text446')" />
                                                </div>
                                                <div class="content darkCard">
                                                    <h5 class="title">{{$t('about.text66')}}</h5>
                                                    <p class="description">{{$t('about.text67')}} </p>
                                                </div>
                                            </div>
                                        </article>
                                    </li>
                                    <li class="timeline">
                                        <article>
                                            <div class="icon"><span class="lineAdded"></span></div>
                                            <div class="timeline-content left d-flex align-items-center">
                                                <div class="stroyImg darkCard">
                                                    <v-lazy-image class="d-block lineBorder" src="/assets/images/swignalProviders.svg" :alt="$t('about.text444')" />
                                                </div>
                                                <div class="content darkCard">
                                                    <h5 class="title">{{$t('about.text68')}}</h5>
                                                    <p class="description"><span class="secondary">{{$t('about.text55')}}</span>{{$t('about.text69')}}</p>
                                                </div>
                                            </div>
                                        </article>
                                    </li>
                                    <li class="timeline">
                                        <article>
                                            <div class="icon"><span class="lineAdded"></span></div>
                                            <div class="timeline-content d-flex align-items-center">
                                                <div class="stroyImg darkCard">
                                                    <v-lazy-image class="d-block lineBorder" src="/assets/images/Computer.svg" :alt="$t('about.text444')" />
                                                </div>
                                                <div class="content darkCard">
                                                    <h5 class="title">{{$t('about.text70')}}</h5>
                                                    <p class="description"><span class="secondary">{{$t('about.text55')}}</span> {{$t('about.text71')}} <span class="secondary">{{$t('about.text55')}}</span>.</p>
                                                </div>
                                            </div>
                                        </article>
                                    </li>
                                    <li class="timeline">
                                        <article>
                                            <div class="icon"><span class="lineAdded"></span></div>
                                            <div class="timeline-content left d-flex align-items-center">
                                                <div class="stroyImg darkCard">
                                                    <v-lazy-image class="d-block lineBorder" src="/assets/images/Rocket.svg" :alt="$t('about.text444')" />
                                                </div>
                                                <div class="content darkCard">
                                                    <h5 class="title">{{$t('about.text72')}}</h5>
                                                    <p class="description"> <span class="secondary">{{$t('about.text55')}}</span>{{$t('about.text73')}}</p>
                                                </div>
                                            </div>
                                        </article>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
</template>
<script>
    import jQuery from 'jquery'
    export default {
        data() {
            return {
                
            };
        },
        mounted() {
                // Timeline Scroll Section
        // --------------------------------------------------------------
            var items = jQuery(".main-timeline li"),
            // timelineHeight = jQuery(".main-timeline ul").height(),
            lineToDraw = jQuery('.draw-line'),
            idTimeline = jQuery('#timeline');
            

            // sets the height that the greyLine (.default-line) should be according to `.timeline ul` height
            jQuery('.default-line').height(idTimeline.height())
            // run this function only if draw line exists on the page
            if(lineToDraw.length) {
                jQuery(window).on('scroll', function () {
                    // Need to constantly get '.draw-line' height to compare against '.default-line'
                    let greyLine = jQuery('.default-line'),
                    /* var redLineHeight = lineToDraw.height(),*/
                    greyLineHeight = greyLine.height(),
                    // var redLineHeight = idTimeline.height(),
                    // greyLineHeight = timelineHeight,
                    windowDistance = jQuery(window).scrollTop(),
                    windowHeight = jQuery(window).height() / 2,
                    timelineDistance = jQuery(".main-timeline").offset().top;
                    if(windowDistance >= timelineDistance - windowHeight) {
                        let line = windowDistance - timelineDistance + windowHeight;
                        if(line <= greyLineHeight) {
                            lineToDraw.css({
                            'height' : line + 10 + 'px'
                            });
                        }
                    }
                    // This takes care of adding the class in-view to the li:before items
                    var bottom = lineToDraw.offset().top + lineToDraw.outerHeight(true);
                    items.each(function(/*index*/){
                        var circlePosition = jQuery(this).offset();
                        if(bottom > circlePosition.top) {             
                            jQuery(this).addClass('in-view');
                        }  
                        else {
                            jQuery(this).removeClass('in-view');
                        }
                    }); 
                });
            }
        },
    }
</script>