 <template>
    <div class="mainWrapper pb-0">
            <section class="blockElement space bg-white">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12 col-xl-9">
                           <ul class="timeLineTabs mb-0 d-flex align-items-center justify-content-center">
                                <li class="borderList p-0">&nbsp;</li>
                                <li><a @click.prevent="timelineTab = 'About'" :class="(timelineTab == 'About')?'active':''" class="button zulu_btn rounded border-button bg-white darkWhite" href="javascript:void(0)">About Us</a></li>
                                <!-- <li><a  @click.prevent="timelineTab = 'Management'" :class="(timelineTab == 'Management')?'active':''" class="button zulu_btn rounded border-button bg-white darkWhite" href="javascript:void(0)">Management</a></li> -->
                                <li><a  @click.prevent="timelineTab = 'Timeline'" :class="(timelineTab == 'Timeline')?'active':''" class="button zulu_btn rounded border-button bg-white darkWhite" href="javascript:void(0)">Timeline</a></li>
                                <li><a  @click.prevent="timelineTab = 'Careers'" :class="(timelineTab == 'Careers')?'active':''" class="button zulu_btn rounded border-button bg-white darkWhite" href="javascript:void(0)">Careers</a></li>
                           </ul>
                        </div>
                    </div>
                </div>
            </section>
            <div class="tabsOne" v-if="timelineTab == 'About'">
                <About />
            </div>
            <!-- <div class="tabsTwo" v-if="timelineTab == 'Management'">
                <Management />
            </div> -->
            <div class="tabsThree" v-if="timelineTab == 'Timeline'">
                <Timeline />
            </div>
            <div class="tabsFour" v-if="timelineTab == 'Careers'">
                <Careers />
            </div>
        </div>
</template>
<script>
    import About from "./tabs/about_us";
    // import Management from "./tabs/management";
    import Timeline from "./tabs/timeline";
    import Careers from "./tabs/careers";
    export default {
        data() {
            return {
                timelineTab:'About',
            };
        },
        components: {
            About,
            // Management,
            Timeline,
            Careers
        },
    }
</script>